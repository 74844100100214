.cg-wrap{background-image:url(../images/board/purple.svg)}
piece.pawn.white{background-image:url('../images/pieces/merida/wP.svg')}
piece.bishop.white{background-image:url('../images/pieces/merida/wB.svg')}
piece.knight.white{background-image:url('../images/pieces/merida/wN.svg')}
piece.rook.white{background-image:url('../images/pieces/merida/wR.svg')}
piece.queen.white{background-image:url('../images/pieces/merida/wQ.svg')}
piece.king.white{background-image:url('../images/pieces/merida/wK.svg')}
piece.pawn.black{background-image:url('../images/pieces/merida/bP.svg')}
piece.bishop.black{background-image:url('../images/pieces/merida/bB.svg')}
piece.knight.black{background-image:url('../images/pieces/merida/bN.svg')}
piece.rook.black{background-image:url('../images/pieces/merida/bR.svg')}
piece.queen.black{background-image:url('../images/pieces/merida/bQ.svg')}
piece.king.black{background-image:url('../images/pieces/merida/bK.svg')}
piece {
  z-index:2;
  background-size: contain;
  background-repeat: no-repeat;
  will-change:transform;
}
piece.react-draggable {
  cursor:pointer;
}
piece.react-draggable.disabled {
  cursor:default;
}

piece.react-draggable-dragging, piece.dragging {
  cursor:move;
  z-index:9;
}

.cg-wrap {
  box-sizing: content-box;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

cg-helper {
  position:absolute;
  width:12.5%;
  padding-bottom:12.5%;
  display:block;
  bottom:0px;
  left:0px;
}
cg-container {
  position:absolute;
  width:800%;
  height:800%;
  display:block;
  bottom:0
}
cg-board {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  line-height: 0;
  background-size:cover;
  cursor:pointer;
}
cg-board square {
  position:absolute;top:0;left:0;width:12.5%;height:12.5%;pointer-events:none
}
cg-board square.move-dest {
  background:radial-gradient(rgba(20,85,30,0.5) 22%,#208530 0,rgba(0,0,0,0.3) 0,rgba(0,0,0,0) 0);
  pointer-events:auto
}
cg-board square.premove-dest {
  background:radial-gradient(rgba(20,30,85,0.5) 22%,#203085 0,rgba(0,0,0,0.3) 0,rgba(0,0,0,0) 0);
}
cg-board square.oc.move-dest {
  background:radial-gradient(transparent 0,transparent 80%,rgba(20,85,0,0.3) 80%);
}
cg-board square.oc.premove-dest {
  background:radial-gradient(transparent 0,transparent 80%,rgba(20,30,85,0.2) 80%);
}
cg-board square.move-dest:hover {
  background:rgba(20,85,30,0.3);
}
cg-board square.premove-dest:hover {
  background:rgba(20,30,85,0.2);
}
cg-board square.last-move {
  will-change:transform;
  background-color:rgba(255, 0, 80, 0.20);
  box-shadow:
    0px 0px 6px 3px rgba(255, 0, 140, 0.8),
    inset 2px 2px 6px 1px rgba(255, 0, 140, 0.95),
    inset -2px -2px 6px 1px rgba(255, 0, 140, 0.95);

}
cg-board square.selected {
  background-color:rgba(20,85,30,0.5);
}
cg-board square.check {
  background:radial-gradient(ellipse at center,rgba(255,0,0,1) 0,rgba(231,0,0,1) 25%,rgba(169,0,0,0) 89%,rgba(158,0,0,0) 100%);
}
cg-board square.current-premove {
  background-color:rgba(180,50,255,0.5);
  box-shadow:
    0px 0px 6px 3px rgba(140, 0, 255, 0.7),
    inset 1px 1px 6px 1px rgba(140, 0, 255, 0.80),
    inset -1px -1px 6px 1px rgba(140, 0, 255, 0.80);
}
.cg-wrap piece {
  background-size:cover;
  position:absolute;
  top:0;
  left:0;
  width:12.5%;
  height:12.5%;
  pointer-events:none;
}
cg-board piece.anim {
  z-index:8;
}
cg-board piece.fading {
  z-index:1;
  opacity:.5;
}
.cg-wrap square.move-dest:hover {
  background-color:rgba(20,85,30,0.3);
}
.cg-wrap piece.ghost {
  opacity:.3;
}

.cg-wrap .cg-custom-svgs {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cg-wrap svg {
  overflow:hidden;
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  pointer-events:none;
  z-index:2;
  opacity:.6;
}
.cg-wrap svg image {
  opacity:.5;
}
.cg-wrap coords {
  position:absolute;
  display:flex;
  pointer-events:none;
  opacity:.8;
  font-size:9px
}
.cg-wrap coords.ranks {
  right:-15px;
  top:0;
  flex-flow:column-reverse;
  height:100%;
  width:12px;
}
.cg-wrap coords.ranks.black{
  flex-flow: column;
}
.cg-wrap coords.files{
  bottom:-16px;
  left:0;
  flex-flow:row;
  width:100%;
  height:16px;
  text-transform:uppercase;
  text-align:center;
}
.cg-wrap coords.files.black{
  flex-flow:row-reverse
}
.cg-wrap coords coord{
  flex:1 1 auto
}
.cg-wrap coords.ranks coord{
  transform: translateY(39%);
}
