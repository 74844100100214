
@font-face {
  font-family: 'AlienEncounters';
  src: url('fonts/Alien-Encounters/Alien-Encounters-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AlienEncountersSolid';
  src: url('fonts/Alien-Encounters/Alien-Encounters-Solid-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SpaceMono';
  src: url('fonts/SpaceMono-Regular.ttf') format('truetype');
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#logo_splash {
  padding: 1em 4em 1em 2em;
  border-radius: 2rem;
  box-shadow:
  /*          0  0 12px 12px #a030b0, */
  /*          2px  0 20px 10px   rgba(240, 20, 200, 0.9), */
  /* inset    2px  0 20px 10px   rgba(240, 20, 200, 0.9), */
  inset    -1px  0 10px 5px   rgba(255, 44, 255, 0.95),
           1px   0 10px 5px   rgba(255, 44, 255, 0.95);
  background-color: #080824;
  text-align: center;
}

#game_countdown {
  position: absolute;
  left: 50%;
  font-family: AlienEncounters;
  padding: 0.5em;
  background-color: rgba(30, 0, 60, 0.9);
  border: 2px solid rgba(170, 30, 255, 0.9);
  border-radius: 1rem;
  box-shadow:
    inset     0px 0 20px 5px rgba(255, 44, 255, 0.95),
              0px 0 8px  2px rgba(255, 34, 255, 0.90),
              0px 0 30px 4px rgba(255, 44, 255, 0.80);
  color: rgba(255, 44, 255, 0.95);
  font-size: 8em;
  top: 40%;
  min-width: 1em;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.bug-img-logo {
  vertical-align: middle;
  max-width: 8em;
  max-height: 8em;
}

.logo-glow {
  animation: glow 1.5s infinite alternate;
}

.bug-logo-text {
  font-family: 'AlienEncounters', 'Helvetica';
  position: relative;
  vertical-align: middle;
  font-size: 5em;
  font-style: italic;
  color: #803cff;
  /* -webkit-text-stroke: 1px white; */
  text-shadow:
    0px 0px 4px rgba(55, 44, 255, 0.95),
    1px 1px 4px rgba(95, 24, 95, 0.95),
    0px 0px 20px black,
    16px 8px 1px rgba(240, 23, 170, 0.28),
    38px 16px 2px rgba(126, 30, 220, 0.21);
}

.bug-logo-text.small {
  animation: small-glow 1.5s infinite alternate;
  color: #b03cff;
  font-size: 2em;
  font-style: italic;
  padding-top: 0.2em;
  text-shadow: 0px 0px 12px #232333;
  max-height: 3em;
  margin-left: 0.5em;
  padding: 2px;
    /* 6px 2px 3px rgba(140,23,250,0.62); */
}

.bug-logo-text .solid {
  font-family: 'AlienEncountersSolid', Roboto;
  font-weight: 500;
}

.main {
  padding-top: 3em;
  flex: auto;
}

.alien {
  font-family: 'AlienEncountersSolid', 'Roboto';
}

.alien.subtitle {
  font-family: "AlienEncounters", "Roboto", "Helvetica";
  font-weight: 300;
  text-align: center;
  color: rgba(180, 0, 255, 0.8);
  font-size: 1.5em;
}

@keyframes small-glow {
  to {
    text-shadow: 0 0 10px red;
  }
}

@keyframes glow {
  to {
    text-shadow: 0 0 50px rgba(255, 32, 200, 0.95);
  }
}

.bug-logo-text {
  font-size: min(4.5rem, 8vw, 16vh);
}

@media screen and (max-width: 700px) {
  .bug-img-logo {
    max-width: 6rem;
    max-height: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .bug-img-logo {
    max-width: 5rem;
    max-height: 5rem;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loginContainer {
  position: relative;
  left: 50%;
  top: 50%;
  height: 100%;
}

#looseLogin {
  position: relative;
  left: -25%;
  top: -25%;
  border-radius: 2rem;
  box-shadow: "10px 5px 5px #404040";
  max-width: 800px;
  background-color: #282c34;
  /* opacity: 100%; */
}

#login.row {
  max-width: 85%;
  margin-left: 5%;
}

#looseLogin.row, #login.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.column {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.color-cell {
  border: 0.1rem solid #bfbeba;
  border-radius: 0.2rem;
  display: block;
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  height: .8rem;
  width: .8rem;
}

.color-cell.black {
  background-color: #333;
}

.color-cell.white {
  background-color: #fff;
}

.team-player {
  display: flex;
  align-items: center;
}

.team-player-handle {
  font-weight: 600;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.team-player-rating {
  margin-left: 4px;
  opacity: 0.70;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.hoverExpose {
  opacity: 0.80;
}

.hoverExpose:hover {
  opacity: 1.0;
}

body {
  overflow-x: hidden;
  margin: 2px;
  /* fallback for old browsers */
  background: #232333;
  /* background: #0f0c29;   */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, #373260, #372050, #473270, #2f0c46, #24042e); */
  /* background: linear-gradient(to right, #3a3b60, #3a326a, #474270, #373260, #302250); */
  /* background: linear-gradient(to right, #300, #030, #003); */
  color: #e9e9e9;
}

.leftBuffer {
  margin-left: max(2em, 3vw);
}

.leftPad {
  padding-left: max(2em, 3vw);
}

body .mono {
  font-family: 'SpaceMono', Courier, Roboto;
}

.med-text {
  font-size: 1rem;
 white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  /* line-height: 1.6; */
  letter-spacing: 0.0075em;
}

.playerData {
  color: rgba(120, 70, 240, 0.95);
  flex: 0 1 auto;
  max-height: 5vh;
  padding: 0.5em 0;
  display: flex;
  max-width: min(95%, 90vh);
  justify-content: space-around;
  text-shadow: 0px 0px 2px rgba(255, 0, 255, 0.8);
}

.playerData.ticking {
  color: rgba(255, 150, 255);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
}

.playerData .timer-icon.low  {
  color: rgba(255, 120, 190);
}

.playerData .timer-icon {
  /* margin-right: calc(min(-1vw, -2vh)); */
  /* flex-grow: 1; */
  flex-shrink: 2;
  position: relative;
  margin-top: 4px;
  top: min(0.5vw, 1.0vh);
  opacity: 1.0;
}

.playerData.ticking .timer-icon {
  visibility: visible;
}

.playerData .timer-icon,
.playerData .timer-icon.flagged {
  visibility: hidden;
}

.clock {
  margin-top: 4px;
  padding-left: 0.2em;
  min-width: 4em;
  text-align: right;
}

.playerData.ticking .timer-icon.low,
.playerData.ticking .clock.low {
  font-weight: 900;
  color: rgba(255, 120, 190);
  text-shadow: 0px 0px 2px rgba(255, 100, 170, 0.7);
  animation: ticking-grow 0.25s infinite alternate;
  animation-timing-function: linear;
  opacity: 0.80;
  transform: scale(1.0);
}

.playerData .marker {
  font-family: Roboto;
  visibility: hidden;
  padding: 0px 0.2em;
}

.playerData.ticking .handle .player {
  animation: ticking-glow 0.50s infinite alternate;
  opacity: 0.70;
}

/* .playerData.ticking .handle .marker { */
/*   opacity: 0.70; */
/* } */

.playerData.ticking .handle .marker {
  visibility: visible;
  animation: ticking-big-glow 0.50s infinite alternate;
  font-weight: 900;
  text-shadow: 0px 0px 3px rgba(255, 30, 180, 0.8);
  display: inline-block;
  /* animation: ticking-big-glow 0.50s infinite alternate; */
  position: relative;
  top: -0.3em;
  transform: translateY(-2px) scale(1.1);
  animation-timing-function: linear;
  opacity: 0.70;
}

.playerData.ticking .marker::after {
  /* transform: translate(-5px, 0); */
  /* font-size: 0.8em; */
  /* content: "\2658"; */
  /* content: "\265e"; */
}

@keyframes ticking-big-glow {
  to {
    transform: scale(1.30);
    opacity: 1.0;
  }
}

@keyframes ticking-grow {
  to {
    transform: scale(1.05);
    opacity: 1.0;
  }
}

@keyframes ticking-glow {
  to {
    opacity: 1.0;
  }
}

.playerData .player {
  text-overflow: ellipsis;
  max-width: 20vw;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.playerData.ticking .player {
  font-weight: 700;
}

.h5 {
  text-overflow: ellipsis;
  font-size: 5rem;
  font-size: min(4vw, 8vh);
}

.h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  /* font-size: clamp(.5rem, min(2vw, 3vh), 1.5rem); */
  font-size: min(3vw, 6vh);
  font-weight: 500;
  /* line-height: 1.6; */
  letter-spacing: 0.0075em;
}

.clamped, .clamped .h6, .clamped .MuiButton-label, .playerData .h6 {
  font-size: clamp(.5em, min(1.5vw, 3vh), 1.5em);
}

#current_games {
  min-width: 30vw;
  max-width: 40vw;
}

#current_games .clamped {
  font-size: clamp(.5em, min(1vw, 2vh), 1.5em);
}

#current_games  .h5 {
  font-size: min(1vw, 2vh);
}

#current_games .h6 {
  font-size: min(0.6vw, 1.2vh);
}

#current_games .h6.alien {
  margin-top: 8px;
}

#current_games .gameOver .gameOverMsg {
  width: 100%;
}

#current_games .countCircle {
  line-height: min(0.5vw, 1.0vh);
  font-size: min(0.5vw, 1.0vh);
  font-weight: 500;
  border-radius: 32%;
  border-width: 1px;
}

.currentGame {
  cursor: pointer;
}

/* Games: Table/Arena */
.countCircle {
  cursor: pointer;
  position: absolute;
  line-height: min(1vw, 4vh);
  font-weight: 800;
  font-size: min(1.3vw, 4vh);
  background-color: #f5f5f5;
  border-radius: 32%;
  display: inline-block;
  border-color: #000;
  border-width: clamp(1.2px, min(.2vw, .4vh), 1.8px);
  border-style: solid;
  color: #303030;
  left: 50%;
  top: 50%;
  transform: translate(10%, 20%);
  z-index: 100;
  font-family: 'SpaceMono';
  padding: .12em .2em .2em .15em;
}

.countCircle.dragging {
  opacity: 0.4;
}

#form_table .MuiInputLabel-shrink {
  /* transform: translate(0, 0); */
}

#form_table .MuiFormLabel-root {
  /* line-height: 1.5; */
  /* color: #e9e9e9; */
}

.roboto {
  font-family: "SpaceMono", "Roboto", "Helvetica", "Arial", sans-serif;
}

body .light {
  color: #dfdfdf;
}

body .light label {
  color: #dfdfdf;
}

body .dark {
  color: #303030;
}

.bold {
  font-weight: 600;
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.cell {
  padding: 4px;
  flex-grow: 0;
  min-width: 220px;
}

#promoDialog {
  overflow: hidden
}

.challenge {
  display: flex;
  justify-content: space-evenly;
}

body .firebaseui-tos {
  color: #c0c0e0;
}

a {
 text-decoration: none;
}
a:link, a:visited {
  color: #dfdfdf;
}
a:hover {
  color: #ffffff;
}

a.firebaseui-link, a.firebaseui-link:visited {
  color: #4255f4;
}

p a.firebaseui-link, p a.firebaseui-link:visited {
  color: #dfdfdf;
}

body .makeStyles-paper-161 {
  color: #dfdfdf;
}

.shadow {
  boxShadow: 10px 5px 5px #404040;
}

.gameOver {
  z-index: 200;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.gameOver .gameOverMsg {
  padding: 4px 4px 8px 4px;
  alignText: center;
  position: relative;
  width: min(34vw, 60vh);
  border: #f0c 1px solid;
  border-radius: 1rem;
  background: rgba(30, 0, 60, 0.9);
}

.cg-holder {
  position: relative;
  width: 100%;
  height: min(100%, 90vh);
}

.cg-holder:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}


.holdings {
  background: linear-gradient(to left,
    rgba(255, 80, 130, 0.3),
    rgba(130, 30, 255, 0.4)
    );
  /* background: rgba(130, 80, 240, 0.4); */
  border-radius: 0.4vw;
  box-shadow:
    inset     0px 0 3px 3px rgba(255, 44, 255, 0.80),
              0px 0 8px  2px rgba(255, 34, 255, 0.70),
              0px 0 15px 3px rgba(255, 44, 255, 0.60);
}

.holdings.black {
}

.heldPiece {
  position: relative;
  height: 20%;
  width: calc(100% - 10px);
  margin-left: 5px;
  text-align: center;
  z-index: 500;
}

.heldPiece.selected {
  background-color: rgba(200, 0, 100, 0.5);
  border-radius: 0.5em;
  box-shadow: 0px 0px 8px 2px rgba(100, 0, 255, 0.8);
  margin-right: 2em;
}

#boardA, #boardB { }

.cg-wrap {
  box-shadow:
  inset    -1px  0 10px 2px   rgba(255, 44, 255, 0.8),
           1px   0 30px 3px   rgba(255, 44, 255, 0.95);
}

#game_message_center {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#game_messages_quick_buttons {
  position: relative;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  border-radius: 0.5em;
  background-color: #401c4d;
  margin: 3px 1em 3px 0px;
  text-align: center;
}

#game_messages_quick_buttons .alien {
  font-family: "AlienEncounters", "Roboto", "Helvetica";
  font-weight: 300;
  text-align: center;
  color: rgba(180, 0, 255, 0.8);
}

#game_messages {
  position: relative;
  flex: auto;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#game_message_input {
  flex: 1 1 1;
}

.message {
  background-color: rgba(255, 0, 220, 0.3);
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px 2px rgba(255, 0, 255, 0.8);
  margin-left: 4px;
  margin-right: 1em;
  margin-top: 4px;
  padding: 0px 1em 2px 2px;
  position: relative;
  text-align: right;
}

.message.self {
  background-color: rgba(170, 50, 255, 0.3);
  box-shadow: 0px 0px 10px 2px rgba(140, 80, 255, 0.2);
  text-align: left;
}

#game_messages_quick_buttons .MuiButton-root {
  padding: 2px 2px;
  min-width: 4px;
  margin: 2px 2px;
  background-color: rgba(130, 30, 255, 0.4);
}

.quick-btn-holder {
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  justify-content: center;
}

.quick-btn-holder button {
  flex: 0 1 auto;
}

.quick-msg {
  height: min(1.8vw, 4vh);
  width: min(1.8vw, 4vh);
}

.quick-msg.white, .quick-msg.black {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.quick-msg .MuiSvgIcon-root {
  font-size: min(1.8vw, 4vh);
  height: calc(min(1.8vw, 4vh) + 2px);
  width: calc(min(1.8vw, 4vh) + 2px);
  color: rgba(255, 60, 140, 0.7);
  transform: translate(-2%, 10%);
}

.message .quick-msg {
  position: relative;
  top: -2px;
  /* height: min(2vw, 4vh); */
  /* width: min(2vw, 4vh); */
  font-size: min(2vw, 4vh);
  padding: 2px 4px;
}

.message .quick-msg .MuiSvgIcon-root {
  position: relative;
  top: 2px;
  height: min(2.2vw, 4.4vh);
  width: min(2.2vw, 4.4vh);
}

#game_messages_quick_buttons .MuiSvgIcon-root {
  position: relative;
  /* text-align: center; */
  /* margin-left: -0.2rem; */
  /* margin-top: -0.4rem; */
  top: max(-0.2vh, -0.2vw);
  height: calc(min(2vw, 4vh) + 0.5rem);
  width: calc(min(2vw, 4vh) + 0.5rem);
  transform: translate(-14%, -11%);
}

.quick-msg.pawn.white{background-image:url('./images/pieces/merida/wP.svg')}
.quick-msg.bishop.white{background-image:url('./images/pieces/merida/wB.svg')}
.quick-msg.knight.white{background-image:url('./images/pieces/merida/wN.svg')}
.quick-msg.rook.white{background-image:url('./images/pieces/merida/wR.svg')}
.quick-msg.queen.white{background-image:url('./images/pieces/merida/wQ.svg')}
.quick-msg.king.white{background-image:url('./images/pieces/merida/wK.svg')}
.quick-msg.pawn.black{background-image:url('./images/pieces/merida/bP.svg')}
.quick-msg.bishop.black{background-image:url('./images/pieces/merida/bB.svg')}
.quick-msg.knight.black{background-image:url('./images/pieces/merida/bN.svg')}
.quick-msg.rook.black{background-image:url('./images/pieces/merida/bR.svg')}
.quick-msg.queen.black{background-image:url('./images/pieces/merida/bQ.svg')}
.quick-msg.king.black{background-image:url('./images/pieces/merida/bK.svg')}

.quick-msg.exchange,
.quick-msg.mates,
.quick-msg.stall,
.quick-msg.watch_time {
  font-size: min(1.7vw, 3vh);
  font-weight: 600;
  /* margin-left: -0.3rem; */
  /* margin-top: -0.7rem; */
  padding: 0.1rem 0.1rem;
  text-align: center;
  transform: translate(-11%, -24%);
}

.quick-msg.exchange,
.quick-msg.mates {
  position: relative;
  left: min(0.2vw, 0.4vh);
}

.quick-msg.stall {
  transform: translate(-3%, -24%);
}

.message .quick-msg.exchange,
.message .quick-msg.mates,
.message .quick-msg.stall,
.message .quick-msg.watch_time {
  font-size: min(2vw, 4vh);
}

.message.NEED_PAWN::after { content: "Get me a Pawn"; }
.message.NEED_BISHOP::after { content: "Get me a Bishop"; }
.message.NEED_KNIGHT::after { content: "Get me a knight"; }
.message.NEED_ROOK::after { content: "Get me a Rook"; }
.message.NEED_QUEEN::after { content: "Get me a Queen"; }

.message.NO_PAWN::after { content: "No pawns"; }
.message.NO_BISHOP::after { content: "No bishops"; }
.message.NO_KNIGHT::after { content: "No knights"; }
.message.NO_ROOK::after { content: "No rooks"; }
.message.NO_QUEEN::after { content: "No queen"; }

.message.NEED_PAWN::after,
.message.NEED_BISHOP::after,
.message.NEED_KNIGHT::after,
.message.NEED_ROOK::after,
.message.NEED_QUEEN::after,
.message.NO_PAWN::after,
.message.NO_BISHOP::after,
.message.NO_KNIGHT::after,
.message.NO_ROOK::after,
.message.NO_QUEEN::after,
.message .quick-msg::after {
  font-size: min(1vw, 2vh);
}

.message .quick-msg.exchange::after { content: "make exchanges"; }
.message .quick-msg.mates::after { content: "mates / I have mate"; }
.message .quick-msg.stall::after { content: "stall"; }
.message .quick-msg.watch_time::after { content: "watch your clock"; }

.quick-msg.exchange .text, .quick-msg.mates .text {
  color: rgba(255, 0, 170);
  text-shadow: 0px 0px 1em rgba(255, 0, 255, 0.5);
}

.quick-msg.stall span.text {
  -webkit-filter: url(#red-filter);
  filter: url(#red-filter);
}

.analysis-moves {
  font-family: SpaceMono;
  font-size: min(1rem, 1vw);
  max-width: calc(100% - 8px);
}

.analysis-moves .titles {
  font-family: "AlienEncountersSolid", "Roboto", "Helvetica";
  font-weight: 500;
  line-height: 2em;
  padding-bottom: min(1vw, 2vh);
}

.analysis-moves .titles .a,
.analysis-moves .titles .b {
  border-radius: 0.3em;
  text-align: center;
}

.analysis-moves .titles .arrow {
  font-family: "Roboto", "Helvetica";
  font-weight: 900;
  font-size: min(1vw, 2vh);
  padding: 4px 6px;
  position: relative;
  top: min(0.2vw, 0.4vh);
}

.analysis-moves .flip-buttons {
  width: 100%;
  font-size: min(1vw, 2vh);
}

.analysis-moves .flip-buttons > span {
  width: 40%;
  position: relative;
  display: inline-block;
  padding: 1em 0.5em;
}

.analysis-moves .titles > *,
.move {
  width: 40%;
  position: relative;
  display: inline-block;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.move {
  cursor: pointer;
}

.analysis-moves .titles .a,
.move.a {
  background: rgba(120, 30, 255, 0.3);
}

.analysis-moves .titles .b,
.move.b {
  background: rgba(255, 30, 255, 0.4);
}

.analysis-moves.moves-list {
  position: relative;
  flex: auto;
  max-height: calc(min(40vw, 90vh) - 10em);
  overflow-y: auto;
  overflow-x: hidden;
}

.move.a.selected {
  background: rgba(120, 30, 255, 0.2);
}

.move.b.selected {
  background: rgba(255, 30, 255, 0.25);
}

.move.selected {
  line-height: 1.4em;
  border-radius: 0.3rem;
  box-shadow:
    inset     0px 0 8px 3px rgba(205, 0, 255, 0.95),
              0px 0 20px 4px rgba(205, 0, 255, 0.80);

}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(70, 20, 155, 0.6);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 30, 170, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 30, 255);
}

#game_message_input {
  max-width: calc(100% - 1.2em);
}
